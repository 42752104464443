/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-04-20 09:02:50
 * @LastEditTime: 2021-10-20 14:14:01
 */
import request from '@/utils/request'
import axios from 'axios'
// 获取文件上传地址OSS
export function getImgSrc (parameter) {
  return request({
    url: '/api/common/file',
    method: 'get',
    params: parameter
  })
}
// 文件流上传
export function upLoadFile (file, uploadImgUrl, contentType) {
  const blobdata = new Blob([file])
  const config = {
    method: 'put',
    url: uploadImgUrl,
    headers: {
      'Content-Type': contentType
    },
    data: blobdata
  }
  return axios(config)
}

// 文件直传
// export function upLoadFile () {}